<template>
  <section class="container faq-column" :class="{ 'l-webscreens': $route.name.includes('webscreens') }">
    <h1 class="faq-header">Frequently Asked Questions (FAQs)</h1>
    <p>
      Get answers to some of the most commonly asked questions about electric vehicles (EVs) and how to charge on the
      Electrify America network.
    </p>
    <accordion>
      <!-- Public Charging -->
      <accordion-item
        title="Public Charging"
        :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0 } }, true)"
      >
        <accordion>
          <accordion-item
            title="How do I use my membership plan to start a charging session at an Electrify America charger?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 0 } })"
          >
            <p>
              As an Electrify America Pass or Pass+ member, if you’ve downloaded the Electrify America app and added a
              valid payment method to your account, you have two ways to start a charge by smartphone:
            </p>
            <div class="flex">
              <div class="body">
                <ol>
                  <li>
                    Using the digital membership pass, which requires tapping or holding the smartphone near the
                    charger’s RFID reader.
                    <ul>
                      <li>
                        First, plug the connector into the vehicle. Follow all steps on the charger screen before
                        tapping or holding your phone near the reader.
                      </li>
                      <li>
                        <b>Android users:</b> Ensure that NFC is turned on in your phone settings and that the
                        smartphone screen is unlocked before tapping the smartphone to the reader—look for the symbol
                        below. The Electrify America app does not need to be open.
                      </li>
                      <li>
                        <b>iOS users:</b> Open Apple Wallet or the Electrify America app. Tap the smartphone to the
                        reader on the right side of the charger—look for the symbol shown here
                      </li>
                    </ul>
                  </li>
                  <li>
                    Using “Swipe to Start Charge” from within the Electrify America app.
                    <ul>
                      <li>
                        Ensure that your smartphone’s location services are enabled, the Electrify America app is
                        installed on your phone, and you are logged into your account.
                      </li>
                      <li>
                        Open the app and from the map screen (or your Favorites), select the charging station where you
                        are located. You must be near the charger to start a charge.
                      </li>
                      <li>
                        Select the charger by referring to the Charger ID number at the top of the charger’s screen, and
                        swipe to start a charging session.
                        <ul style="list-style: circle">
                          <li>Hint: Look for the last two digits on the charger your vehicle is plugged into.</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ol>
              </div>
              <div class="images">
                <img
                  loading="lazy"
                  class="image"
                  src="@/assets/images/Mobile-App/placeholder@2x.jpg"
                  alt="Diagram showing how to tap a smartphone to the charging station’s RFID reader."
                />
                <SwipeToStart class="image" alt="The mobile app’s “Swipe to Start Charge” slider." />
              </div>
            </div>
          </accordion-item>
          <accordion-item
            title="If I’m not a member, how do I start a charging session at an Electrify America charger?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 1 } })"
          >
            <p>
              As a guest (without an Electrify America account), you can pay by credit card. Every Electrify America
              charger is equipped with a credit card reader, to make charging easily available and accessible to all EV
              drivers.
            </p>
            <p>
              First, plug the connector into the vehicle. Follow all steps on the touchscreen before entering a Visa or
              MasterCard credit card for payment. If your card has a chip, please insert it fully into the reader.
            </p>
            <p>
              A temporary authorization of $50 will be applied to all credit or debit cards, and will be released after
              final billing takes place. The duration of this hold is determined by your card provider.
            </p>
          </accordion-item>
          <accordion-item
            title="How do I locate an Electrify America charger?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 2 } })"
          >
            <p>
              Find an Electrify America charging station near you
              <router-link class="link" :to="{ name: 'locate-charger-en' }">using our charger locator map</router-link>.
              You can also download the Electrify America app to locate an available station, track stations coming
              soon, and get notifications whey they're available.
            </p>

            <div style="display: flex; margin-left: 30px">
              <a href="https://apps.apple.com/us/app/electrify-america/id1458030456" rel="noopener" target="_blank">
                <img
                  loading="lazy"
                  style="width: 93px"
                  :src="require('@/assets/images/Global/app-store-logo@2x.webp')"
                  alt="Download on app store"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.ea.evowner&rdid=com.ea.evowner"
                rel="noopener"
                target="_blank"
              >
                <img
                  loading="lazy"
                  style="width: 110px; margin-left: 10px"
                  :src="require('@/assets/images/Global/google-play-badge@2x.webp')"
                  alt="Get it on google play"
                />
              </a>
            </div>
          </accordion-item>
          <accordion-item
            title="How long will it take to charge my EV at an Electrify America station?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 3 } })"
          >
            <p>
              Charging time varies for each session and depends on factors such as your EV type, battery age, and
              weather conditions. Many modern EVs can reach up to an 80% charge in about 30 minutes using our Ultra-Fast
              chargers. Check your owner's manual or specifications of the vehicle online to find your battery capacity
              in kilowatt-hours (kWh). Remember, the higher the kW, the faster the charge.
            </p>
          </accordion-item>
          <accordion-item
            title="Can I use Plug&Charge with my EV at your stations?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 4 } })"
          >
            <p>
              Plug&Charge is now available at all Electrify America charging stations. However, it is only available for
              capable EVs.
            </p>
            <p>
              To find out if your EV is Plug&Charge capable, please contact your vehicle’s manufacturer or look at your
              vehicle's owner's manual for more information.
            </p>
          </accordion-item>
          <accordion-item
            title="Why do some chargers say “temporarily reduced”? "
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 5 } })"
          >
            <p>
              Oftentimes when you encounter chargers delivering reduced power, it means our maintenance team has
              temporarily reduced power on the charger for improvements and updates. While these chargers still operate,
              they function at reduced power levels. We understand the inconvenience of slower charging speeds, and
              we're always working to enhance your experience for faster charging in the future.
            </p>
            <p>
              Please note that not all sites are affected. To see the power level of chargers at a station ahead of
              time, use the Electrify America mobile app.
            </p>
            <p>Thank you for your patience and support.</p>
          </accordion-item>
          <accordion-item
            title="What should I do if I encounter a charging error?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 6 } })"
          >
            <p>
              In many cases, charging errors are temporary and can be resolved by stopping and restarting your session.
              Here's what to do:
            </p>
            <ol>
              <li>
                If the charging error has not ended your session, manually end your charging session by pressing "Stop"
                in the mobile app or on the charger.
              </li>
              <li>
                Unplug the charging cable from your vehicle. If necessary, unlock your electric vehicle before
                unplugging the charging cable.
              </li>
              <li>Reconnect the charging cable and start a new charging session.</li>
              <li>
                If the error persists, we suggest moving to another charger and reporting the issue by calling the
                Contact Center number located above the charger screen.
              </li>
            </ol>
            <p>
              For more detailed instructions on how to charge, visit
              <router-link class="link" :to="{ name: 'charging-your-ev-en' }">How to Charge Your EV</router-link>
            </p>
          </accordion-item>
          <accordion-item
            title="Do I need an Electrify America Pass or Pass+ membership to charge?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 7 } })"
          >
            <p>
              No, an Electrify America Pass or Pass+ membership is not required, but having one comes with benefits. For
              example, Pass+ Members save about 25% each time they charge. To learn more about Pass or Pass+ benefits,
              <router-link class="link" :to="{ name: 'mobile-app-en' }">click here</router-link>.
            </p>
          </accordion-item>
          <accordion-item
            title="Can I use membership cards or apps from other charging networks at Electrify America stations?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 8 } })"
          >
            <p>
              No, membership cards or apps from other charging networks cannot be used at Electrify America stations.
            </p>
          </accordion-item>
          <accordion-item
            title="Can I get an RFID card from Electrify America?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 9 } })"
          >
            <p>
              Currently, Electrify America does not offer RFID cards. To initiate a charge, you can use the Electrify
              America mobile app, Apple Wallet, credit or debit card, or Plug&Charge for capable vehicles.
            </p>
          </accordion-item>
          <accordion-item
            title="Can I purchase Electrify America stock?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 10 } })"
          >
            <p>No, Electrify America is not a publicly traded company.</p>
          </accordion-item>
          <accordion-item
            title="What do Electrify America Ultra-Fast and Hyper-Fast charger power levels listed on the station mean?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 11 } })"
          >
            <p>
              Our
              <router-link :to="{ name: 'glossary-en', query: { load: 8 } }" class="link">DC fast</router-link>,
              <router-link :to="{ name: 'glossary-en', query: { load: 20 } }" class="link">Ultra-Fast</router-link>, and
              <router-link :to="{ name: 'glossary-en', query: { load: 11 } }" class="link">Hyper-Fast</router-link>
              chargers are capable of delivering up to 50 kW, 150 kW, or 350 kW. Higher power levels offer faster
              charging for capable vehicles. The blue and green labels on each charger indicate the maximum available
              <router-link :to="{ name: 'glossary-en', query: { load: 14 } }" class="link">kW</router-link>
              by connector type.
            </p>
            <p>
              All electric vehicles are different, so check your car’s manufacturer to learn about the charging
              capabilities of your EV. If your car is not compatible with the maximum charging level at your charger,
              that’s okay. Our chargers will automatically give you the highest power level that your vehicle can
              handle.
            </p>
            <p>
              <em> Tip: A DC-compatible EV can be charged at any available Electrify America station. </em>
            </p>
          </accordion-item>
          <accordion-item
            title="What do the labels on the Electrify America chargers indicate?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 12 } })"
          >
            <p>
              We use blue and green color labels to help drivers easily determine the correct type of connector and
              corresponding power level for their electric vehicle. At Electrify America chargers, green power level
              labels refer to
              <router-link :to="{ name: 'glossary-en', query: { load: 3 } }" class="link">CCS</router-link>
              connectors, while blue power labels indicate
              <router-link :to="{ name: 'glossary-en', query: { load: 4 } }" class="link">CHAdeMO</router-link>. For
              example, a green label reading “up to 350 kW” means that the charger offers up to 350 kW for a
              CCS-compatible EV. If your car is not compatible with the maximum charge indicated, the charger will
              automatically give you the highest level that it can handle.
            </p>
            <p>
              <img loading="lazy" width="100px" :src="require('@/assets/images/FAQ/faqs_chademo.png')" alt="CHAdeMo" />
              {{ '\u00a0 \u00a0 \u00a0 \u00a0' }}
              <img loading="lazy" width="100px" :src="require('@/assets/images/FAQ/faqs_ccs.png')" alt="CCS" />
            </p>
            <p>
              <em>
                Tip: If you need support at the station, contact our Customer Assistance team. We are available 24 hours
                a day / 7 days a week by dialing the toll-free number, 1-833-632-2778.
              </em>
            </p>
          </accordion-item>
          <accordion-item
            title="If one connector on a charger is already plugged into a vehicle, can I use the other one?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 13 } })"
          >
            <p>
              Though there are two connectors at each Electrify America charger, only one can be used at a time. Having
              two connectors ensures that it’s easy for you to plug in—no matter where your car’s charging port is
              located. If either connector is in use, look for the next nearest charger. Most charging stations have at
              least four chargers to increase availability.
            </p>
          </accordion-item>
          <accordion-item
            title="What is an idle fee?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 14 } })"
          >
            <p>
              Drivers who do not unplug and move their vehicle ten minutes after their charging session is complete will
              be charged a $0.40 per-minute idle fee. This fee encourages drivers to move their car promptly so that
              others can use the charger. You can be notified when your charging session is complete by text message
              <sup>2</sup>
              or see it on the charger screen.
            </p>
            <p>
              <small>
                <sup>2</sup>
                Messaging and data rates may apply.
              </small>
            </p>
          </accordion-item>
          <accordion-item
            title="How do I get a receipt?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 15 } })"
          >
            <p>
              If you have a membership, log in to the Electrify America app, go to your Account, and select “Charge
              history.” You can filter your history by date and share it by email.
            </p>
            <p>
              You can also receive your receipt by text message, if you provide your mobile phone number at the start of
              a session. (Messaging and data rates may apply.) The total cost will also be displayed on the charging
              station screen at the end of your session.
            </p>
          </accordion-item>
          <accordion-item
            title="How can I track the progress of my charging session?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 16 } })"
          >
            <p>
              If you provide your mobile phone number at the start of your session, you can get text message updates to
              see:
            </p>
            <ul style="margin-left: 25px">
              <li>When charging has started</li>
              <li>When charging has slowed</li>
              <li>When charging has stopped</li>
              <li>Your final receipt</li>
            </ul>
            <p>
              If you have the Electrify America app on your mobile phone you can also check the status of your charging
              session directly from the app. Download the Electrify America app:
            </p>
            <div style="display: flex; margin-left: 25px">
              <a
                href="https://itunes.apple.com/us/app/electrify-america/id1458030456?mt=8"
                rel="noopener"
                target="_blank"
              >
                <img
                  loading="lazy"
                  style="width: 93px"
                  :src="require('@/assets/images/Global/app-store-logo@2x.webp')"
                  alt="Download on the App Store"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.ea.evowner&rdid=com.ea.evowner"
                rel="noopener"
                target="_blank"
              >
                <img
                  loading="lazy"
                  style="width: 110px; margin-left: 10px"
                  :src="require('@/assets/images/Global/google-play-badge@2x.webp')"
                  alt="get it on google play"
                />
              </a>
            </div>
            <p>
              <em>
                Tip: You will only receive text messages from Electrify America on the mobile phone number related to
                each individual active charging session.
              </em>
            </p>
            <p><img loading="lazy" width="200" :src="require('@/assets/images/FAQ/faqs_progress.jpg')" alt="" /></p>
            <p>The charger screen may also display data that is updated each minute, including:</p>
            <ul style="margin-left: 25px">
              <li>Current power level, measured in kW</li>
              <li>Total time spent charging</li>
              <li>Current session cost</li>
              <li>
                Energy delivered to the vehicle: measured in
                <router-link :to="{ name: 'glossary-en', query: { load: 14 } }" class="link">kWh</router-link>
                and cumulative over the session
              </li>
              <li>
                Initial and current vehicle
                <router-link :to="{ name: 'glossary-en', query: { load: 18 } }" class="link"
                  >state of charge (SOC)</router-link
                >, and time to 80% SOC<sup>3</sup>
                <br />
                <small> <sup>3</sup>Not all electric vehicles provide this information back to our chargers. </small>
              </li>
            </ul>
            <p>
              <em>
                Tip: EVs typically accept reduced charging levels as a battery charges, often slowing at around 80%.
                This means that the charging speed may slow down toward the end of your session.
              </em>
            </p>
            <p>
              When the charge is complete and the vehicle unplugged, your receipt will be sent as a text if you provided
              your phone number at the beginning of the session. This is in addition to your receipt shown on the
              charger screen.
            </p>
          </accordion-item>
          <accordion-item
            title='What does "state of charge" mean?'
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 17 } })"
          >
            <p>
              <router-link :to="{ name: 'glossary-en', query: { load: 18 } }" class="link"
                >State of charge, or SOC</router-link
              >, indicates how “full” your EV’s battery is. When charging begins, most EVs send the charger an initial
              SOC, which is displayed on the charger screen. If available, the SOC will be updated as your car charges,
              though some EVs are not capable of sending our chargers this information.
            </p>
            <p>
              Our chargers will also display the time it takes to reach 80% SOC, if your car transmits that information.
              EVs slow their charging rate as the battery approaches a full charge, which typically occurs around 80%.
              The SOC that this occurs at is often referred to as “bulk charge.” Vehicles will continue charging to 100%
              at this slower rate to preserve your battery life.
            </p>
            <p><img loading="lazy" width="300" :src="require('@/assets/images/FAQ/faqs_soc.jpg')" alt="" /></p>
          </accordion-item>
          <accordion-item
            title="How do I stop the charging session?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 18 } })"
          >
            <p>To end the charging session, just press the “Stop” button at the bottom right of the charger screen.</p>
            <p>
              You can also stop a charging session through the Electrify America app. Select “Stop Charging” on the
              Current Session screen.
            </p>
            Tips:
            <ul>
              <li>
                If you do not want to start a charging session, but have already plugged in, you might need to press
                “Cancel” on the charger screen to release the connector from your vehicle.
              </li>
              <li>Depending on your vehicle model, you may also need to unlock your car to remove the connector.</li>
            </ul>
          </accordion-item>
          <accordion-item
            title="What should I do if I get a charging error?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 19 } })"
          >
            <p>
              The screen may ask you to unplug the connector from your vehicle and start a new session by plugging it in
              again. You will need to resubmit payment to begin a new session. If it’s still not working, please move to
              a nearby charger at that location if one is available. The Electrify America Customer Assistance team is
              also here to help 24 hours a day, 7 days a week. Dial the toll-free number at 1-833-632-2778.
            </p>
          </accordion-item>
          <accordion-item
            title="Why is my charging slowing down after 80% state of charge?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 20 } })"
          >
            <p>
              Toward the end of your session, you may notice your charging speeds (kW) slow down. Many vehicles “bulk
              charge” and slow their charging speed when they reach about 80%*.
            </p>
            <p>
              Because of this, we recommend unplugging your vehicle after bulk charging has occurred. When starting a
              charging session, most EVs share their initial
              <router-link class="link" :to="{ name: 'glossary-en', query: { load: 18 } }"
                >state of charge (SOC)</router-link
              >
              with the charger. SOC indicates how "full" the battery is and can be viewed on the charger screen or in
              the Electrify America mobile app.
            </p>
            <p>
              To stay updated on your SOC and other charging details, download the Electrify America app from the
              <a
                href="https://apps.apple.com/us/app/electrify-america/id1458030456"
                rel="noopener"
                target="_blank"
                class="link"
              >
                Apple App Store
              </a>
              or
              <a
                href="https://play.google.com/store/apps/details?id=com.ea.evowner&rdid=com.ea.evowner"
                rel="noopener"
                target="_blank"
                class="link"
              >
                Google Play Store </a
              >.
            </p>
            <p>
              *Exact battery percentage may vary. Check with your vehicle’s manufacturer to learn when bulk charge
              occurs for your vehicle.
            </p>
          </accordion-item>
          <accordion-item
            title="Why does my EV take longer to charge in the cold?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 21 } })"
          >
            <p>In colder temperatures, your EV’s software typically lowers the charging power it accepts.</p>
            <p>
              So, when temperatures are colder, it’s important to plan ahead for longer charge times, whether the trip
              is part of your daily commute or a longer road trip. For more information, read our cold weather charging
              tips
              <a
                href="https://media.electrifyamerica.com/en-us/releases/170"
                rel="noopener"
                target="_blank"
                class="link"
              >
                here</a
              >.
            </p>
          </accordion-item>
          <accordion-item
            title="Is it okay to park my EV outside in the cold?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 22 } })"
          >
            <p>
              Yes, it is fine to park your EV outside during the colder months. However, keeping your EV in a warmer
              garage can help the battery charge faster and hold its charge longer.
            </p>
          </accordion-item>
          <accordion-item
            title="Are there any tips when charging my EV in colder temperatures?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 23 } })"
          >
            <img
              class="image--infograph"
              src="@/assets/images/FAQ/faqs_cold_weather_tips.webp"
              alt="1. Anticipate longer charging sessions 2. Temperature changes may impact EV Range 3. Plan your charging session 4. Park indoors when you can 5. Utilize your EVs cold weather features"
            />
          </accordion-item>
          <accordion-item
            title="Why am I seeing some charging stations offline for upgrades or planned maintenance?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 24 } })"
          >
            <p>
              Electrify America is committed to providing a high-quality charging experience for our customers. To
              ensure these standards are met, we are upgrading some of our legacy hardware to the newest generation of
              chargers. During these upgrades, stations will be temporarily offline. We update our
              <a
                class="link"
                target="_blank"
                href="https://cloud.email.electrifyamerica.com/network-and-planned-maintenance"
                >Network Status page</a
              >
              weekly with the list of charging stations that will be temporarily offline due to upgrades, planned
              maintenance, vandalism, or cable theft.
            </p>
          </accordion-item>
          <accordion-item
            title="Is Electrify America currently experiencing a network outage?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 25 } })"
          >
            <p>
              We are committed to providing our EV drivers with accurate, transparent information on our network. If
              there is a widespread service interruption on our network, our team will provide an update on the
              <a
                class="link"
                target="_blank"
                href="https://cloud.email.electrifyamerica.com/network-and-planned-maintenance"
                >Network Status page</a
              >, to the best of our ability. Bookmark this page and check back for the latest update on our network
              status. As always, real-time station availability can be viewed within the Electrify America
              <router-link class="link" :to="{ name: 'mobile-app-en' }">mobile app</router-link>.
            </p>
          </accordion-item>
          <accordion-item
            title="What should I do if I encounter a charger error?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 26 } })"
          >
            <p>
              In many cases, charging errors are temporary and can be resolved by stopping and restarting your session.
              Here's what to do:
            </p>
            <ol>
              <li>
                If the charging error has not ended your session, manually end your charging session by pressing "Stop"
                in the mobile app or on the charger.
              </li>
              <li>
                Unplug the charging cable from your vehicle. If necessary, unlock your electric vehicle before
                unplugging the charging cable.
              </li>
              <li>
                Reconnect the charging cable and start a new charging session. Please be aware that you have about 45
                seconds to present payment after plugging in, or the session will time out. If you're using the "Swipe
                to Start" feature in the Electrify America app, you must plug in within 45 seconds after swiping.
              </li>
              <li>
                If the error persists, we suggest moving to another charger and reporting the issue by calling the
                Contact Center number located above the charger screen.
              </li>
            </ol>
            <p>
              For more detailed instructions on how to charge, visit
              <router-link class="link" :to="{ name: 'charging-your-ev-en' }">How to Charge Your EV</router-link>.
            </p>
          </accordion-item>
          <accordion-item
            title="How can I host a charger station at my business?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 27 } })"
          >
            <p>
              If you are interested in becoming a site host, submit your information
              <router-link class="link" :to="{ name: 'real-estate-en' }">here</router-link>. A team member will be in
              contact if there is a business need.
            </p>
          </accordion-item>
          <accordion-item
            title="How do I unplug if the connector is stuck in my vehicle?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 0, loadInner: 28 } })"
          >
            <p>If you are unable to unplug, please follow the suggested steps below:</p>
            <ol>
              <li>Check to make sure the session has stopped.</li>
              <li>Confirm that your vehicle is unlocked.</li>
              <li>Make sure there isn’t tension in the cable.</li>
              <li>
                Check if the button on the connector is stuck in the depressed position. If so, push the connector in,
                you may hear an audible click. Once you hear a click, press the button and try to remove the connector
                by pulling it straight out.
              </li>
              <li>If you still need help, call us at 1-833-632-2778.</li>
            </ol>
          </accordion-item>
        </accordion>
      </accordion-item>
      <!-- Payment & Membership -->
      <accordion-item
        title="Payment & Membership"
        :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 1 } }, true)"
      >
        <accordion>
          <accordion-item
            title="How is pricing determined?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 1, loadInner: 0 } })"
          >
            <p>
              Depending on your location, you pay to charge either by the kWh or by the minute. In per-minute locations,
              the maximum amount of power your vehicle can accept (which your vehicle communicates to the charger at the
              beginning of your charging session) determines your power level and price.
            </p>
          </accordion-item>
          <accordion-item
            title="How does auto-reload work during my first charging session?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 1, loadInner: 1 } })"
          >
            <p>
              When you use an Electrify America station for the first time, you'll be billed an amount you preselected
              in our mobile app during your initial account setup. This billed amount is held in your account to cover
              any additional costs that aren't included in your plan, such as idle fees and applicable taxes, or for
              future charging sessions. To make sure your charging experience is seamless, your Electrify America
              account will automatically reload with the fund amount you preselected when it drops below $5. To change
              your auto-reload amount, visit the Account tab in the Electrify America mobile app, where you can select
              from the options $10, $20, $40, or $60.
            </p>
          </accordion-item>
          <accordion-item
            title="What payment methods are accepted?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 1, loadInner: 2 } })"
          >
            <p>
              We accept credit or debit card payment directly at the charger using Visa, Mastercard, American Express,
              or Discover. We also accept payment through the Electrify America mobile app, apps from other automakers,
              Apple Pay, and Google Wallet.
            </p>
          </accordion-item>
          <accordion-item
            title="Why was I charged $50 at the charging station when I paid with my credit card?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 1, loadInner: 3 } })"
          >
            <p>
              The $50 you see is a temporary authorization, not an actual charge. Once you are done charging and the
              transaction has settled, the $50 hold is removed and a single charge is processed for the transaction
              cost. Depending on your bank, the hold is typically removed within 24-48 hours once the transaction is
              settled.
            </p>
          </accordion-item>
          <accordion-item
            title="In per-minute locations, how does power level pricing work?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 1, loadInner: 4 } })"
          >
            <p>
              When you plug in to charge, your vehicle tells the charger the maximum power it can accept. Based on that
              communication, your vehicle will be assigned to the corresponding power level offered by the charger.
            </p>
            <p>
              Your charging speed may fluctuate due to factors like weather, age of your battery, and how full your
              battery is (your state of charge). The per-minute price stays the same for the entire session, regardless
              of how the charging speed may vary.
            </p>
          </accordion-item>
          <accordion-item
            title="In per-kWh locations, how does pricing work?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 1, loadInner: 5 } })"
          >
            <p>
              Where feasible, Electrify America has transitioned to kWh-based pricing. In these locations, you pay for
              the energy your vehicle receives, regardless of temperature, your battery’s capacity, the time it takes
              for your vehicle to charge, or other variable factors.
            </p>
          </accordion-item>
          <accordion-item
            title="How do I pay for charging and for the Pass+ subscription fee?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 1, loadInner: 6 } })"
          >
            <div class="flex">
              <div class="body">
                <p>
                  When you sign up for Electrify America Pass or Pass+ in the mobile app, follow the prompts to add your
                  credit card to your account. Then select an amount to automatically load money to your Electrify
                  America balance.
                </p>
                <p>
                  This amount will be added to your account the first time you charge your vehicle at an Electrify
                  America charging station after signing up. Every time your balance falls to or below $5, your account
                  will reload with the amount you selected.
                </p>
                <p>
                  The cost for charging, idling, and any applicable taxes will be deducted from your balance at the end
                  of your charging session.
                </p>
                <p>
                  If you’re a Pass+ member, your monthly subscription fee will be charged to your credit or debit card
                  when you sign up and deducted from your Electrify America balance monthly thereafter.
                </p>
              </div>
              <div class="images">
                <SwipeToStart class="image" alt="" />
              </div>
            </div>
          </accordion-item>
          <accordion-item
            title="What happens if my Electrify America account runs out of money?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 1, loadInner: 7 } })"
          >
            <p>
              If your payment method is declined during a charging session, please update it to allow Electrify America
              to auto-fill your account and continue charging your vehicle.
            </p>
            <p>If the payment method is not updated, charging will stop when your account balance reaches $0.</p>
          </accordion-item>
          <accordion-item
            title="How do I switch from one membership type to another?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 1, loadInner: 8 } })"
          >
            <p>
              In the app, go to your Account and select “Plans.” Follow the prompts to upgrade to Pass+ or to change
              plan types.
            </p>
            <p>
              Please note that a request to change from Pass+ to Pass membership will go into effect on the first day of
              the next billing cycle, which coincides with the monthly anniversary of your sign-up date.
            </p>
          </accordion-item>
          <accordion-item
            title="How do I delete my account?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 1, loadInner: 9 } })"
          >
            <p>If you delete your Pass+ membership, you will no longer enjoy savings of about 25% on charging.</p>
            <p>
              You can cancel your membership anytime in the app. Go to your Account and select the pencil icon to edit
              your profile. Then select “Delete Account” and follow the prompt.
            </p>
          </accordion-item>
          <accordion-item
            title="Why is EV charging priced on a per-kWh basis in some states and on a per-minute basis in others?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 1, loadInner: 10 } })"
          >
            <p>
              Pricing on a per-minute basis means pricing based on <u>how long</u> the charger was delivering energy to
              the EV. Pricing on a per-kWh basis means pricing based on <u>how much</u> energy the charger delivered to
              the EV. We believe pricing on a per-kWh basis offers a fair and consistent charging experience to EV
              drivers. We are working with regulators to follow the various processes that are required for us to
              provide EV charging on a per-kWh basis. This is an ongoing process and we look forward to further
              developments that may allow us to provide EV charging on a per-kWh basis throughout the rest of the
              states.
            </p>
          </accordion-item>
          <accordion-item
            title="Is EV charging at my favorite location priced on a per-kWh basis or per-minute basis?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 1, loadInner: 11 } })"
          >
            <p>
              Our pricing varies by state. You can find pricing for each state on our
              <router-link class="link" :to="{ name: 'pricing-en' }">Pricing page</router-link>, or on our
              <router-link class="link" :to="{ name: 'locate-charger-en' }">Locate a Charger page</router-link>
              in the station details. You can find pricing in the
              <router-link class="link" :to="{ name: 'mobile-app-en' }">Electrify America app</router-link> as well—just
              select your favorite station from the map.
            </p>
          </accordion-item>
          <accordion-item
            title="How is the new pricing structure better than your old pricing structure?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 1, loadInner: 12 } })"
          >
            <p>
              Electrify America is excited to introduce pricing for EV charging on a per-kilowatt-hour basis in many
              states throughout the US. This means drivers will now pay for <u>how much</u> energy is delivered from the
              charger to their EV instead of <u>how long</u> the charger delivered energy to their EV during their
              charging session.
            </p>
            <p>
              Where we do provide EV charging on a per-minute basis, we have simplified our pricing. High-power chargers
              will have only two power levels instead of three. The power level of your session is determined by the
              rate of charge your EV communicates to the charger. The price you will pay per-minute is displayed on the
              charger after the session begins.
            </p>
            <p>
              Consider enrolling in
              <router-link class="link" :to="{ name: 'pricing-en' }"> our Pass+ plan</router-link>, which saves you up
              to 25% on charging!
            </p>
          </accordion-item>
          <accordion-item
            title="Does this new pricing change my Premium Offer plan?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 1, loadInner: 13 } })"
          >
            <p>
              Premium Offer plans that include complimentary charging aren’t affected by these changes. Once your plan
              expires or you exceed your plan benefits for a session, the remainder of your charging will automatically
              be billed at
              <router-link class="link" :to="{ name: 'pricing-en' }">our Pass plan pricing</router-link>. You can choose
              to enroll in <router-link class="link" :to="{ name: 'pricing-en' }">Pass+</router-link> for additional
              savings.
            </p>
          </accordion-item>
          <accordion-item
            title="If I am an existing Pass+ member, do I need to do anything to get the new pricing plan?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 1, loadInner: 14 } })"
          >
            <p>No, your plan will automatically be updated with the new pricing.</p>
          </accordion-item>
        </accordion>
      </accordion-item>
      <!-- Mobile App -->
      <accordion-item
        title="Mobile App"
        :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 2 } }, true)"
      >
        <accordion>
          <accordion-item
            title="How do I access my Electrify America account?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 2, loadInner: 0 } })"
          >
            <p>
              You'll need the Electrify America mobile app to create and log in to your Electrify America account.
              Download the Electrify America app from the
              <a
                href="https://apps.apple.com/us/app/electrify-america/id1458030456"
                rel="noopener"
                target="_blank"
                class="link"
              >
                Apple App Store
              </a>
              or
              <a
                href="https://play.google.com/store/apps/details?id=com.ea.evowner&rdid=com.ea.evowner"
                rel="noopener"
                target="_blank"
                class="link"
              >
                Google Play Store </a
              >. Once downloaded, you can sign up or log in to your account.
            </p>
          </accordion-item>
          <accordion-item
            title="Can I use the Electrify America app with CarPlay and Android Auto?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 2, loadInner: 1 } })"
          >
            <p>
              Yes! Please ensure your vehicle is compatible with CarPlay or Android Auto and your phone is up to date.
              Here are a few ways you can use the Electrify America app in vehicles compatible with CarPlay and Android
              Auto:
            </p>
            <ul style="margin-left: 25px">
              <li>Navigate to charging stations while on the go</li>
              <li>View charger details</li>
              <li>Manage your charging session</li>
            </ul>
          </accordion-item>
          <accordion-item
            title="To use the Electrify America app with CarPlay and Android Auto, what’s the latest version of software needed on my phone?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 2, loadInner: 2 } })"
          >
            <p>The Electrify America app is supported by both iOS 16+ and Android 9+.</p>
          </accordion-item>
          <accordion-item
            title="What is Multi-Factor Authentication, and how do I verify my account?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 2, loadInner: 3 } })"
          >
            <p>
              Multi-Factor Authentication (MFA) adds an extra layer of security to protect your Electrify America
              account. When logging in, you will first enter your username and password, then be prompted to verify your
              identity with a one-time verification code sent to your email.
            </p>
            <p>
              To complete verification, enter the code exactly as shown on the “Verify Your Identity” screen within the
              app and select “Continue.” This process helps prevent unauthorized access to your account.
            </p>
            <p>You can also follow these steps when logging in through the Electrify America app:</p>
            <ol>
              <li>Open the Electrify America app and log in to your account.</li>
              <li>
                A one-time verification code will be automatically sent to your email associated with your account.
              </li>
              <li>
                Return to the Electrify America app and enter the one-time verification code exactly as shown in the
                “Verify Your Identity” screen.
              </li>
              <li>Select “Continue” to complete the verification process.</li>
              <li>Once completed, MFA will be enabled on your account for added security.</li>
            </ol>
          </accordion-item>
          <accordion-item
            title="I cannot access the email associated with my account. How can I complete Multi-Factor Authentication?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 2, loadInner: 4 } })"
          >
            <p>
              If you are unable to access the email associated with your Electrify America account, you will need to
              request an account deletion and create a new account with an email you can access.
            </p>
          </accordion-item>
          <accordion-item
            title="I received an email with a Multi-Factor Authentication code to verify my account, but I did not try to log in. What should I do?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 2, loadInner: 5 } })"
          >
            <p>
              If you received a Multi-Factor Authentication (MFA) code but did not attempt to log in, it could indicate
              an unauthorized login attempt. For security, we recommend resetting your password immediately. You can do
              this through the Electrify America app or website.
            </p>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/oi488X5WRu4?si=XqWIHFnz9Y4QHlXf"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </accordion-item>
        </accordion>
      </accordion-item>
      <!-- Renewable Energy & Sustainability -->
      <accordion-item
        title="Renewable Energy & Sustainability"
        :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 3 } }, true)"
      >
        <accordion>
          <accordion-item
            title="What is a solar canopy?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 3, loadInner: 0 } })"
          >
            <p>
              A solar canopy is a covering structure that holds solar panels and offers protection from the weather.
            </p>
          </accordion-item>
          <accordion-item
            title="How fast is solar EV charging?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 3, loadInner: 1 } })"
          >
            <p>
              Today, Electrify America’s 100% solar-powered chargers offer Level 2 speeds, charging capable vehicles at
              a maximum rate of 3.3 kW.
            </p>
            <p>
              Charging stations equipped with solar canopies integrate with the local electrical grid (and, in some
              locations, on-site energy storage) to help us deliver DC fast charging.
            </p>
            <p>
              You can also find each charger’s maximum rate by checking the
              <router-link class="link" :to="{ name: 'mobile-app-en' }">Electrify America app</router-link> or the
              charger’s screen.
            </p>
          </accordion-item>
          <accordion-item
            title="Where are Electrify America’s solar chargers located?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 3, loadInner: 2 } })"
          >
            <p>
              Currently, all Electrify America charging stations with solar features (both the fully solar stations and
              our on-site solar canopy) are located in California.
            </p>
          </accordion-item>
          <accordion-item
            title="How can I learn more about solar energy in general?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 3, loadInner: 3 } })"
          >
            <p>
              You can learn more about solar energy and photovoltaics for your home and other uses by visiting the
              <a
                rel="noopener"
                target="_blank"
                class="link"
                href="https://www.energy.gov/eere/solar/how-does-solar-work"
                >U.S. Department of Energy’s website.</a
              >
            </p>
          </accordion-item>
          <accordion-item
            title="How can I learn more about the environmental impact of energy from utilities in my area?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 3, loadInner: 4 } })"
          >
            <p>
              The electrical grid in each region of the U.S. puts out differing emission levels. This often varies by
              time of day and weather conditions, which may influence renewable energy generation. To see emissions in
              your ZIP code, visit the
              <a rel="noopener" target="_blank" class="link" href="https://www.epa.gov/egrid/power-profiler#/"
                >U.S. Environmental Protection Agency’s Power Profiler.</a
              >
            </p>
          </accordion-item>
        </accordion>
      </accordion-item>
      <!-- Premium Offers & Charging Benefits -->
      <accordion-item
        title="Premium Offers & Charging Benefits"
        :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 4 } }, true)"
      >
        <accordion>
          <accordion-item
            title="How do I enroll in my complimentary premium offer?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 4, loadInner: 0 } })"
          >
            <p>
              The enrollment process varies by vehicle manufacturer. Some vehicle manufacturers use the Electrify
              America app while others have their own mobile app to enroll. To determine which app is required for your
              Premium Offer, visit our
              <router-link class="link" :to="{ name: 'premium-offers-en' }"> Premium Offers webpage</router-link>, where
              you can click on your car's manufacturer to find detailed information.
            </p>
            <p>
              For automotive manufacturers using the Electrify America app, the Premium Offer requires a one-time
              enrollment. Use the enrollment code provided by your dealer to redeem your complimentary charging within
              the Electrify America app.
            </p>
            <ol>
              <li>
                Download the Electrify America mobile app<reference-link :num="1">
                  Your mobile carrier's messaging, data, and other rates and fees will apply to your use of the
                  Electrify America mobile application</reference-link
                >
                and create your account
              </li>
              <li>Select <strong>Premium Offer</strong> in the Account tab</li>
              <li>Tap <strong>I have an enrollment code</strong></li>
              <li>Enter your enrollment code and select your plan</li>
              <li>
                Accept the Plan Disclosure and add a backup payment method for any fees that are not covered by your
                complimentary charging*
              </li>
            </ol>
            <p>
              *To help ensure you can charge uninterrupted, your Electrify America account balance automatically reloads
              when it falls below $5. You’ll be billed the auto-reload amount you select in the app the first time you
              charge at an Electrify America station, and the billed amount will be held in your account to cover costs
              not covered by this plan, such as idle fees and applicable taxes. Drivers who do not unplug and move their
              vehicle ten minutes after their charging session is complete will be charged a $0.40 per-minute idle fee.
              This fee encourages drivers to move their car promptly so that others can use the charger. You can be
              notified when your charging session is complete by text message (your mobile carrier’s messaging, data and
              other rates may apply) or see it on the charger screen.
            </p>
          </accordion-item>
          <accordion-item
            title="Why do I need to set an auto-reload amount when my Premium Offer covers my charging costs?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 4, loadInner: 1 } })"
          >
            <p>Your Premium Offer covers most of the cost of charging but does not cover:</p>
            <ul>
              <li>Idle fees</li>
              <li>Some commercial stations’ prices*</li>
              <li>Premium offer charging time limitations</li>
            </ul>
            <p>
              The auto-reload amount allows you to cover these costs while charging uninterrupted. Check your vehicle’s
              terms and conditions for the precise details of your Premium Offer.
            </p>
            <p>
              * See the Electrify America
              <router-link class="link" :to="{ name: 'locate-charger-en' }">Locate a Charger</router-link> page for
              exact commercial station location details
            </p>
          </accordion-item>
          <accordion-item
            title="Why does the charger display a cost when using my Premium Offer complimentary charging benefit?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 4, loadInner: 2 } })"
          >
            <p>
              The charger shows the initial price of the charge before any promotions and taxes are applied. At the end
              of your session, you'll see your price following the application of your Premium Offer benefit.
            </p>
          </accordion-item>
          <accordion-item
            title="Why did I receive a communication that I am violating the terms of my OEM Premium Offer plan?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 4, loadInner: 3 } })"
          >
            <p>
              Each month the Electrify America team reviews instances of alleged abuse of premium offer plans. If your
              charging history shows multiple instances of intentional abuse, you will receive a communication that your
              account has been flagged for abuse. If you do not correct your behavior within one month time, you will
              receive a second communication notifying you that your premium offer plan has been terminated, effective
              immediately.
            </p>
            <p>Common behavior that violates our Terms of Use:</p>
            <div>
              <ul>
                <li>
                  <strong>Charging an unauthorized vehicle on your premium plan:</strong>
                  <span>
                    Your complimentary charging benefit should only be used for the VIN associated with the Plan vehicle
                    you entered when signing up for this promotion in your Electrify America account. No other vehicle
                    is eligible to receive this credit through your account. If you drive and charge multiple vehicles
                    at our stations, make sure you select the right plan in the Electrify America app or OEM app before
                    initiating a charging session.
                    <router-link
                      :to="{ name: 'mobile-faq-en', query: { loadOuter: 4, loadInner: 4 } }"
                      class="link medium"
                      >Click here to learn how to select a plan in the Electrify America app.</router-link
                    >
                  </span>
                </li>
                <li>
                  <strong>Waiting period between eligible charging sessions:</strong>
                  <span>
                    If your plan offers a set period of complimentary charging, be aware that there is a mandatory
                    60-minute wait period between charging sessions. This means that after your initial 30-minute
                    charge, you must wait 60 minutes before plugging in for another session. Unplugging and immediately
                    re-plugging to extend your charging time breaches our Terms of Use. Don’t worry, if your charging
                    session fails midway through and you need to initiate a new one, that’s on us - you won’t be flagged
                    for a possible breach of the Terms. If you need to continue charging after the complimentary period,
                    your session will automatically transition to a paid session for the remainder of your charge.
                  </span>
                </li>
                <li>
                  <strong>Commercial Use:</strong>
                  <span>
                    Your promotional benefit plan may not be used for commercial purposes, including but not limited to,
                    fleet use or use in a taxi or ride share program.
                  </span>
                </li>
              </ul>
            </div>
          </accordion-item>
          <accordion-item
            title="How do I select the appropriate charging plan for my vehicle before starting a charge?"
            :onTrigger="() => updateURL({ name: 'mobile-faq-en', query: { loadOuter: 4, loadInner: 4 } })"
          >
            <p>
              If you drive and charge multiple vehicles at our stations, make sure you select the right plan in the
              Electrify America app or OEM app before initiating a charging session.
            </p>
            <p>
              To toggle between plans within the Electrify America app – Navigate to the Swipe to Start screen and click
              the plan name under the Plan section. This will open a list of all the plans available so you can select
              the appropriate one for your vehicle.
            </p>
            <iframe
              class="youtubeVideo"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/1qTdqeWYoMg?si=YqRcb8YHRXsplZHM"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </accordion-item>
        </accordion>
      </accordion-item>
    </accordion>
  </section>
</template>

<script>
import SwipeToStart from '@/assets/images/Mobile-App/swipe to start.svg';
import Accordion from '../components/Accordion/Accordion.vue';
import AccordionItem from '../components/Accordion/AccordionItem.vue';
import ReferenceLink from '../components/ReferenceLink/ReferenceLink.vue';

export default {
  name: 'aggregateFAQ',
  components: {
    Accordion,
    AccordionItem,
    SwipeToStart,
    ReferenceLink,
  },
  metaInfo: {
    title: 'FAQ | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/mobile-faq/' }],
  },
  computed: {
    loadOuter() {
      return this.$route.query.loadOuter;
    },
    loadInner() {
      return this.$route.query.loadInner;
    },
  },
  methods: {
    updateURL(data, isParent = false) {
      const routeParams = this.$route.query;
      const dataParams = data.query;

      const changeInParams =
        parseInt(routeParams.loadInner, 10) !== parseInt(dataParams.loadInner, 10) ||
        parseInt(routeParams.loadOuter, 10) !== parseInt(dataParams.loadOuter, 10);

      const changeInOuterParams = parseInt(routeParams.loadOuter, 10) !== parseInt(dataParams.loadOuter, 10);

      if ((isParent && changeInOuterParams) || changeInParams) {
        this.$router.replace(data);
      }
    },
    scrollToQuestion() {
      // PARENT ACCORDION
      const parentAccordion = document.querySelector('.accordion');
      const parentAccordionId = parentAccordion.id.split('-')[0];

      // Get all the items in the parent accordion by filtering out nested accordion content
      const parentItems = Array.from(parentAccordion?.querySelectorAll('[class~=accordion-trigger]')).filter(
        (item) => !item.closest('.accordion-content')
      );

      if (this.loadOuter >= 0 && this.loadInner >= 0) {
        const targetParentItem = parentItems[this.loadOuter];
        const targetParentItemId = targetParentItem?.id.split('-')[0];

        // CHILD ACCORDION
        const childAccordion = targetParentItem?.nextElementSibling.querySelector('.accordion');
        const childAccordionId = childAccordion?.id.split('-')[0];

        const childItems = childAccordion?.querySelectorAll('[class~=accordion-trigger]');
        const targetChildItem = childItems[this.loadInner];
        const targetChildItemId = targetChildItem?.id.split('-')[0];

        // Trigger parent and child accordion items to open
        this.$root.$emit('toggleAccordion', {
          accordionID: parentAccordionId,
          uniqueID: targetParentItemId,
        });

        this.$root.$emit('toggleAccordion', {
          accordionID: childAccordionId,
          uniqueID: targetChildItemId,
        });

        // Scroll open item into view
        setTimeout(() => {
          const el = document.getElementById(targetChildItem?.id);
          el?.scrollIntoView({ behavior: 'smooth' });
        }, 500);
      } else if (this.loadOuter >= 0) {
        const targetParentItem = parentItems[this.loadOuter];
        const targetParentItemId = targetParentItem?.id.split('-')[0];
        // Trigger parent and child accordion items to open
        this.$root.$emit('toggleAccordion', {
          accordionID: parentAccordionId,
          uniqueID: targetParentItemId,
        });

        // Scroll open item into view
        setTimeout(() => {
          const el = document.getElementById(targetParentItem?.id);
          el?.scrollIntoView({ behavior: 'smooth' });
        }, 500);
      }
    },
  },
  mounted() {
    this.scrollToQuestion();
  },
  watch: {
    $route() {
      // Prevents the page from scrolling to the top when the query parameters in the URL get updated (default behaviour of Vue router on route change)
      this.scrollToQuestion();
    },
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  padding: 100px 20px 32px 20px;
  max-width: 1200px;
  margin: 0 auto;

  ul {
    margin: 0;
    list-style: disc;
    margin-inline-start: 30px;
  }

  ol {
    padding: 0 0 0 45px;
    ul {
      padding: 16px 0 0 0;
      ul {
        padding: 0 0 0 40px;
      }
    }
  }
  .faq-header {
    margin: 0 0 32px 0;
    font-weight: $fw-light;
    line-height: 1;
  }
  .image {
    &--infograph {
      @media (max-width: 750px) {
        max-width: 100%;
      }
    }
  }
  .flex {
    display: flex;
    flex-direction: column;

    @media (min-width: 750px) {
      flex-direction: row;
    }

    .body {
      flex: 1;
    }

    .images {
      margin-left: 50px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;
      @media (min-width: 750px) {
        flex-direction: column;
      }
      .image {
        width: 240px;
        height: auto;
      }

      .image:nth-child(1) {
        @media (max-width: 750px) {
          margin-bottom: 30px;
        }
      }

      .image:nth-child(2) {
        max-height: 70px;
      }
    }
  }

  .accordion-content {
    @media (min-width: 750px) {
      margin-left: 30px;
    }
    .panel {
      padding-top: 20px;
      padding-bottom: 30px;
    }

    p {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
  .youtubeVideo {
    padding-left: 30px;
    max-width: 90%;
  }
}
</style>
